import React, { useRef } from "react"
import { navigate } from "gatsby"

import LocaleLink from "../../LocaleLink"

import Icon_Close from "../../../images/svg/icon_close.svg"
import Icon_Search from "../../../images/svg/icon_search.svg"
import Logo_Sanitarium from "../../../images/svg/sanitarium_logo.svg"

import { useLocale } from "../../../context/LocaleContext"
import useDictionary from "../../../hooks/useDictionary"

interface MobileMenuHeaderProps {
  closeMobMenu: () => void
  pageContext: any
}

const defaultProps = {}

export const MobileMenuHeader: React.FC<MobileMenuHeaderProps> = props => {
  const localeDir = props.pageContext.language

  // refs
  const searchRef = useRef<HTMLInputElement>(null)

  // events
  const handleSearchButton = () => {
    if (searchRef?.current?.value)
      navigate(`/${localeDir}/search/?s=${searchRef.current.value}`)
  }

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef?.current?.value)
      navigate(`/${localeDir}/search/?s=${searchRef.current.value}`)
  }

  return (
    <div className="cell shrink">
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell auto">
            <LocaleLink
              to="/"
              className="mobNav__logo"
              aria-label="Home"
              pageContext={props.pageContext}
            >
              <Logo_Sanitarium />
            </LocaleLink>
          </div>
          <div className="cell shrink">
            <button onClick={props.closeMobMenu}>
              <Icon_Close />
            </button>
          </div>
        </div>
        <div className="grid-x grid-margin-x mobNav__search">
          <div className="cell auto">
            <label>
              <span className="show-for-sr">
                {useDictionary("Type to search", props.pageContext.locale)}
              </span>
              <input
                type="text"
                className="mobNav__searchField"
                placeholder={useDictionary("Search", props.pageContext.locale)}
                onKeyUp={handleSearch}
                ref={searchRef}
              />
            </label>
          </div>
          <div className="cell shrink">
            <button onClick={handleSearchButton}>
              <Icon_Search />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

MobileMenuHeader.defaultProps = defaultProps

export default MobileMenuHeader
