/****************************************************

	Utilities - Locales

****************************************************/
import { Config } from "../models/config"
import { DEFAULT_LANG, LOCALE_LOOKUPS } from "./constants"

/****************************************************
	get config based on locale
****************************************************/
export const getLocaleConfig = (
  config: { nodes: Config[] },
  locale: string = "en-au"
): Config | undefined => {
  if (!config) return undefined
  return config.nodes.find((x: Config) => x.publish_details.locale === locale)
}

export const getLocaleDir = (locale: string) => {
  const dirs = Object.keys(LOCALE_LOOKUPS)
  const dir = dirs.find(
    (x: string) =>
      LOCALE_LOOKUPS[x as keyof typeof LOCALE_LOOKUPS].locale === locale
  )
  return dir || ""
}

export const getLocaleByLanguage = (language: string) => {
    const localeObj =  LOCALE_LOOKUPS[language as keyof typeof LOCALE_LOOKUPS] ?? LOCALE_LOOKUPS[DEFAULT_LANG]
    return localeObj.locale;
}

export const getLangLocale = (langPart: string) => {
  langPart = langPart ?? DEFAULT_LANG;
  const lang = langPart.toLowerCase();
  const locale = getLocaleByLanguage(lang);

  return { locale, lang };
}