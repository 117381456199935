import React from "react"

import LinkWrapper from "../LinkWrapper"

import { NavItemModel } from "../../models/nav"

interface NavItemProps extends NavItemModel {
  cssClass?: string
  tabIndex?: number
  setActiveIndex?: (x: number) => void
  pageContext: any
}

const defaultProps = {}

export const NavItem: React.FC<NavItemProps> = props => {
  if (!props.main_link || !props.label) return null

  const closeSubNav = () => {
    if (props.setActiveIndex) props.setActiveIndex(-1)
  }

  return (
    <li
      className={props.cssClass || ""}
      onMouseOver={closeSubNav}
      onFocus={closeSubNav}
      role="presentation"
    >
      <LinkWrapper
        anchor={props.main_link}
        cssClass="navLink"
        role="menuitem"
        tabIndex={props.tabIndex}
        pageContext={props.pageContext}
      >
        {props.label}
      </LinkWrapper>
    </li>
  )
}

NavItem.defaultProps = defaultProps

export default NavItem
