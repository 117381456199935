import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MiniCard from "../../CardTypes/MiniCard"

import { useLocale } from "../../../context/LocaleContext"
import { getPlainText, getPrettyURL } from "../../../utils"
import { RecipeCollectionModel, RecipeModel } from "../../../models/pages"
import useDictionary from "../../../hooks/useDictionary"

interface GroupPresetProps {
  pageContext: any
}

const defaultProps = {}

export const RecipesGroup: React.FC<GroupPresetProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      itemsau: allContentstackRecipe(
        limit: 3
        sort: { order: DESC, fields: updated_at }
        filter: {
          publish_details: { locale: { eq: "en-au" } }
          title: { ne: "do not delete" }
        }
      ) {
        nodes {
          ...RecipeFields
        }
      }

      collectionsau: allContentstackRecipeCollection(
        limit: 3
        sort: { order: DESC, fields: updated_at }
        filter: {
          publish_details: { locale: { eq: "en-au" } }
          title: { ne: "do not delete" }
          feature_in_menu: { ne: false }
        }
      ) {
        nodes {
          ...RecipeCollectionFields
        }
      }
      itemsnz: allContentstackRecipe(
        limit: 3
        sort: { order: DESC, fields: updated_at }
        filter: {
          publish_details: { locale: { eq: "en-nz" } }
          title: { ne: "do not delete" }
        }
      ) {
        nodes {
          ...RecipeFields
        }
      }

      collectionsnz: allContentstackRecipeCollection(
        limit: 3
        sort: { order: DESC, fields: updated_at }
        filter: {
          publish_details: { locale: { eq: "en-nz" } }
          title: { ne: "do not delete" }
          feature_in_menu: { ne: false }
        }
      ) {
        nodes {
          ...RecipeCollectionFields
        }
      }
    }
  `)
  const locale = props.pageContext.locale
  const listing: RecipeModel[] =
    locale === "en-nz"
      ? query.itemsnz.nodes
      : query.itemsau.nodes
          .filter((x: RecipeModel) => x.publish_details.locale === locale)
          .slice(0, 3)
  const collections: RecipeCollectionModel[] =
    locale === "en-nz"
      ? query.collectionsnz.nodes
      : query.collectionsau.nodes
          .filter(
            (x: RecipeCollectionModel) => x.publish_details.locale === locale
          )
          .slice(0, 3)

  if (listing.length === 0) return null

  return (
    <div className="subNav__articles">
      <div className="subNav__heading">{useDictionary("Latest Recipes", props.pageContext.locale)}</div>
      <div className="grid-x subNav__cards">
        {listing.map(item => {
          return (
            <div key={item.id} className="cell shrink">
              <MiniCard
                heading={getPlainText(item.hero.heading) || ""}
                link={getPrettyURL(item)}
                listingImage={item.hero.mini_img}
                pageContext={props.pageContext}
              />
            </div>
          )
        })}
      </div>

      <div className="subNav__heading">{useDictionary("Feature Collections", props.pageContext.locale)}</div>
      <div className="grid-x subNav__cards">
        {collections.map(item => {
          return (
            <div key={item.id} className="cell shrink">
              <MiniCard
                heading={getPlainText(item.hero.heading) || ""}
                link={getPrettyURL(item)}
                listingImage={item.listing?.mini_img || item.hero.mini_img}
                pageContext={props.pageContext}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

RecipesGroup.defaultProps = defaultProps

export default RecipesGroup
