import React, { useState, useRef, useLayoutEffect } from "react"
import cx from "classnames"

import MobileMenuProducts from "./MobileMenuProducts"
import NavItem from "../NavItem"

import { getLink } from "../../../utils"
import { NavItemModel } from "../../../models/nav"
import { LinkData } from "../../../models/common"

interface MobileMenuItemProps extends NavItemModel {
  index: number
  activeIndex: number
  setActiveIndex: (x: number) => void
  pageContext: any
}

const defaultProps = {}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = props => {
  // state
  const [fullHeight, setFullHeight] = useState(0)

  // refs
  const contentRef = useRef<HTMLUListElement>(null)

  // effects
  useLayoutEffect(() => {
    if (contentRef?.current) setFullHeight(contentRef.current.scrollHeight)
  }, [contentRef])

  // events
  const toggleMenu = () =>
    props.setActiveIndex(props.index === props.activeIndex ? -1 : props.index)

  if (
    !props.main_link ||
    !props.label ||
    !props.sub_nav ||
    props.sub_nav.length === 0
  )
    return null

  // get the main link
  const linkData: LinkData | undefined = getLink(props.main_link[0])
  if (!linkData) return null

  const isActive = props.activeIndex === props.index

  // CSS classes
  const buttonClass = cx({
    navLink: true,
    active: isActive,
  })

  return (
    <li className="mobNav__menuItem" role="presentation">
      <button
        className={buttonClass}
        aria-controls={props.id}
        aria-haspopup="true"
        aria-expanded={isActive}
        onClick={toggleMenu}
      >
        {props.label}
      </button>
      {props.sub_nav.map(group => {
        if (group.links.length > 0 || group.preset === "Products")
          return (
            <ul
              key={props.id}
              id={props.id}
              className="mobSubNav__menu"
              ref={contentRef}
              style={isActive ? { height: fullHeight } : { height: 0 }}
              role="menu"
              aria-hidden={props.activeIndex !== props.index}
            >
              {group.preset === "Products" && (
                <MobileMenuProducts
                  tabIndex={isActive ? 0 : -1}
                  pageContext={props.pageContext}
                />
              )}
              {group.links.map(item => (
                <NavItem
                  key={item.id}
                  {...item}
                  cssClass="mobSubNav__menuItem"
                  tabIndex={isActive ? 0 : -1}
                  pageContext={props.pageContext}
                />
              ))}
              <NavItem
                id={`test`}
                label={`All ${props.label}`}
                main_link={props.main_link}
                sub_nav={[]}
                internal={{ type: "test" }}
                cssClass="mobSubNav__menuItem"
                tabIndex={isActive ? 0 : -1}
                pageContext={props.pageContext}
              />
            </ul>
          )
      })}
    </li>
  )
}

MobileMenuItem.defaultProps = defaultProps

export default MobileMenuItem
