import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { getPlainText, getPrettyURL } from "../../../utils"
import { getLocaleConfig } from "../../../utils/utils_locales"
import LocaleLink from "../../LocaleLink"

interface MobileMenuProductsProps {
  tabIndex?: number
  pageContext: any
}

const defaultProps = {}

export const MobileMenuProducts: React.FC<MobileMenuProductsProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      items: allContentstackConfig(
        filter: { code_id: { eq: "menuProducts" } }
      ) {
        nodes {
          pages {
            ... on Contentstack_page {
              ...ContentPageFields
            }
          }
          publish_details {
            locale
          }
        }
      }
    }
  `)

  const locale = props.pageContext.locale;
  const items = getLocaleConfig(query.items, locale)?.pages || [];

  return (
    <>
      {items.map(product => {
        const heading =
          getPlainText(product.nav_label) ||
          getPlainText(product.hero?.heading) ||
          ""
        const listingImage =
          product.listing?.listing_img || product.hero?.listing_img

        return (
          <li
            key={product.id}
            className="mobSubNav__menuItem"
            role="presentation"
          >
            <LocaleLink
              to={getPrettyURL(product)}
              className="navLink"
              pageContext={props.pageContext}
            >
              {listingImage && (
                <GatsbyImage
                  image={listingImage?.gatsbyImageData}
                  alt={heading}
                  className="nav__image"
                  objectFit="contain"
                  objectPosition="center"
                  style={{ height: 53, width: 100 }}
                />
              )}
              <div className="nav__label">{heading}</div>
            </LocaleLink>
          </li>
        )
      })}
    </>
  )
}

MobileMenuProducts.defaultProps = defaultProps

export default MobileMenuProducts
