import React from "react"
import { Link, GatsbyLinkProps } from "gatsby"

import { useLocale } from "../context/LocaleContext"
import { getPrettyURL } from "../utils"

interface Props extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref"> {
  pageContext: any
}

export const defaultProps = {}

export const LocaleLink: React.FC<Props> = props => {
  if(!props.pageContext){
    console.log("props.pageContext is undefined")
  }
  const localeDir = props.pageContext.language
  const { pageContext, ...domProps } = props
  if (!props.children) return null

  return (
    <Link {...domProps} to={getPrettyURL(`/${localeDir || ""}/${props.to}`)}>
      {props.children}
    </Link>
  )
}

LocaleLink.defaultProps = defaultProps

export default LocaleLink
