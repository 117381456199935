import React, { useState, useEffect, useRef, useMemo } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { CSSTransition } from "react-transition-group"

import DesktopMenuItem from "./DesktopMenuItem"
import DesktopSubMenu from "./DesktopSubMenu"
import NavItem from "../NavItem"
import NavOverlay from "../NavOverlay"
import Icon_Search from "../../../images/svg/icon_search.svg"

import { useLocale } from "../../../context/LocaleContext"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import { getLocaleConfig } from "../../../utils/utils_locales"
import { NavItemModel } from "../../../models/nav"
import useDictionary from "../../../hooks/useDictionary"

interface DesktopMenuProps {
  location: Location
  pageContext: any
}

const defaultProps = {}

export const DesktopMenu: React.FC<DesktopMenuProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      menu: allContentstackConfig(filter: { code_id: { eq: "mainMenu" } }) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)
  const locale = props.pageContext.locale
  const localeDir = props.pageContext.language
  const mainMenu = (
    getLocaleConfig(query.menu, locale)?.navigation || []
  ).filter(
    x => x.internal?.type === "Contentstack_navigation_item"
  ) as NavItemModel[]

  //console.log(locale)
  // state
  const [activeIndex, setActiveIndex] = useState(-1)
  const [allowSubNav, setAllowSubNav] = useState(true)
  const [searchOpen, setSearchOpen] = useState(false)
  const [menuItemRefs, setMenuItemRefs] = useState<
    React.RefObject<HTMLLIElement>[]
  >([])

  // refs
  const menuRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  // list of menu item refs (for sub nav positioning)
  useEffect(() => {
    if (locale && mainMenu.length) {
      setMenuItemRefs(
        Array(mainMenu.length)
          .fill(0)
          .map(() => React.createRef())
      )
    }
  }, [locale])

  // close menu on page change
  useEffect(() => {
    setActiveIndex(-1)
    setAllowSubNav(false)
    setTimeout(() => {
      setAllowSubNav(true)
    }, 1000)
  }, [props.location?.pathname])

  // events
  const changeSubNav = (index: number) => {
    if (allowSubNav) setActiveIndex(index)
  }

  const handleSearchButton = () => {
    if (!searchOpen) {
      setSearchOpen(true)
      searchRef?.current?.focus()
    } else if (searchRef?.current?.value)
      navigate(`/${localeDir}/search/?s=${searchRef.current.value}`)
    else setSearchOpen(false)
  }

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchRef?.current?.value)
      navigate(`/${localeDir}/search/?s=${searchRef.current.value}`)
  }

  // close menu on click outside
  const handleClickOutside = () => setActiveIndex(-1)
  useOnClickOutside(menuRef, handleClickOutside)

  return (
    <>
      <nav className="nav show-for-large" ref={menuRef}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell auto" style={{ position: "relative" }}>
              <ul className="nav__menu" role="menu">
                {mainMenu.map((item, index) => {
                  if (item.sub_nav && item.sub_nav.length > 0)
                    return (
                      <DesktopMenuItem
                        key={item.id}
                        {...item}
                        index={index}
                        activeIndex={activeIndex}
                        setActiveIndex={changeSubNav}
                        ref={menuItemRefs[index]}
                        pageContext={props.pageContext}
                      />
                    )
                  return (
                    <NavItem
                      key={item.id}
                      {...item}
                      cssClass="nav_menuItem"
                      setActiveIndex={changeSubNav}
                      pageContext={props.pageContext}
                    />
                  )
                })}
              </ul>

              {mainMenu.map((item, index) => {
                if (item.sub_nav && item.sub_nav.length > 0)
                  return (
                    <CSSTransition
                      key={item.id}
                      in={index === activeIndex}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <DesktopSubMenu
                        key={item.id}
                        id={item.id || `sub_nav_${index}`}
                        nav={item.sub_nav}
                        index={index}
                        setActiveIndex={changeSubNav}
                        offset={menuItemRefs[index]?.current?.offsetLeft || 0}
                        buttonWidth={
                          menuItemRefs[index]?.current?.getBoundingClientRect()
                            .width || 0
                        }
                        pageContext={props.pageContext}
                      />
                    </CSSTransition>
                  )
              })}
            </div>
            <div className="cell shrink">
              <div className="grid-x align-middle">
                <div className="cell auto nav__search__holder">
                  <label className={`nav__search ${searchOpen ? "open" : ""}`}>
                    <span className="show-for-sr">{useDictionary("Type to search", props.pageContext.locale)}</span>
                    <input
                      type="text"
                      className="nav__searchField"
                      placeholder={useDictionary("Search", props.pageContext.locale)}
                      onKeyUp={handleSearch}
                      ref={searchRef}
                      tabIndex={searchOpen ? 0 : -1}
                    />
                  </label>
                </div>
                <div className="cell shrink">
                  <button onClick={handleSearchButton}>
                    <Icon_Search />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <NavOverlay active={activeIndex !== -1} />
    </>
  )
}

DesktopMenu.defaultProps = defaultProps

export default DesktopMenu
