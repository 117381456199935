import React, { CSSProperties } from "react"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { getAltText } from "../../utils"
import { CmsImage } from "../../models/common"

interface BgImageProps {
  image?: CmsImage
  backgroundPosition?: string
  altText?: string
  cssClass?: string
  children?: React.ReactElement
  style?: CSSProperties
}

const defaultProps = {}

export const BgImage: React.FC<BgImageProps> = props => {
  if (!props.image) return <>{props.children}</>

  // background image
  const imageData = getImage(props.image.gatsbyImageData)
  const bgImageData = convertToBgImage(imageData)

  // background position
  const bgPosition = props.backgroundPosition?.toLowerCase() || "center"

  // alt text
  const altText = props.altText || getAltText(props.image) || ""

  return (
    <BackgroundImage
      {...bgImageData}
      alt={altText}
      style={{ backgroundPosition: bgPosition, ...props.style }}
      className={`bgImage ${props.cssClass || ""}`}
    >
      {props.children}
    </BackgroundImage>
  )
}

BgImage.defaultProps = defaultProps

export default BgImage
