import React from "react"

import LocaleLink from "./LocaleLink"

import { getLink, getPrettyURL } from "../utils"
import { PageModels } from "../models/pages"
import { AnchorModel } from "../models/nav"

interface LinkWrapperProps {
  anchor?: AnchorModel[]
  page?: PageModels
  cssClass?: string
  tabIndex?: number
  role?: string
  children?: React.ReactElement | string
  pageContext: any
}

const defaultProps = {}

export const LinkWrapper: React.FC<LinkWrapperProps> = props => {
  // console.log("LinkWrapper: ", props)
  if (!props.children) return null

  let linkData = props.anchor?.length ? getLink(props.anchor[0]) : undefined
  let pageData = props.page ? getPrettyURL(props.page) : undefined

  // if no link, return children
  if (!linkData && !pageData)
    return <span className={props.cssClass || ""}>{props.children}</span>

  if (pageData) {
    return (
      <LocaleLink
        to={pageData}
        className={props.cssClass || ""}
        tabIndex={props.tabIndex}
        role={props.role}
        pageContext={props.pageContext}
      >
        {props.children}
      </LocaleLink>
    )
  } else if (linkData?.isExternal) {
    return (
      <a
        href={linkData.url}
        className={props.cssClass || ""}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={props.tabIndex}
        role={props.role}
      >
        {props.children}
      </a>
    )
  } else if (linkData?.url) {
    return (
      <LocaleLink
        to={linkData.url}
        className={props.cssClass || ""}
        tabIndex={props.tabIndex}
        role={props.role}
        pageContext={props.pageContext}
      >
        {props.children}
      </LocaleLink>
    )
  } else return null
}

LinkWrapper.defaultProps = defaultProps

export default LinkWrapper
