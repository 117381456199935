import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CSSTransition } from "react-transition-group"
import FocusTrap from "focus-trap-react"

import MobileMenuHeader from "./MobileMenuHeader"
import MobileMenuFooter from "./MobileMenuFooter"
import MobileMenuItem from "./MobileMenuItem"
import NavItem from "../NavItem"
import NavOverlay from "../NavOverlay"

import { useCurrentWidth } from "../../../hooks/useCurrentWidth"
import { NavItemModel } from "../../../models/nav"
import { useLocale } from "../../../context/LocaleContext"
import { getLocaleConfig } from "../../../utils/utils_locales"

interface MobileMenuProps {
  location: Location
  menuOpen: boolean
  setMobMenuOpen: (x: boolean) => void
  pageContext: any
}

const defaultProps = {}

export const MobileMenu: React.FC<MobileMenuProps> = props => {
  const query = useStaticQuery(graphql`
    query {
      mainMenu: allContentstackConfig(filter: { code_id: { eq: "mainMenu" } }) {
        nodes {
          ...ConfigFields
        }
      }
      footerMenu: allContentstackConfig(
        filter: { code_id: { eq: "footerMenu" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  const locale = props.pageContext.locale
  const localeDir = props.pageContext.language
  //console.log("localeDir", localeDir)
  const mainMenu = (
    getLocaleConfig(query.mainMenu, locale)?.navigation || []
  ).filter(
    x => x.internal?.type === "Contentstack_navigation_item"
  ) as NavItemModel[]
  const footerMenu = (
    getLocaleConfig(query.footerMenu, locale)?.navigation || []
  ).filter(
    x => x.internal?.type === "Contentstack_navigation_item"
  ) as NavItemModel[]

  // state
  const [activeIndex, setActiveIndex] = useState(-1)

  // refs
  const menuRef = useRef<HTMLDivElement>(null)

  // hooks
  const width = useCurrentWidth()

  // close menu on page change
  useEffect(() => {
    closeMobMenu()
  }, [props.location?.pathname])

  useEffect(() => {
    if (props.menuOpen && width > 1024) closeMobMenu()
  }, [width, props.menuOpen])

  // events
  const closeMobMenu = () => {
    props.setMobMenuOpen(false)
    setTimeout(() => {
      setActiveIndex(-1)
    }, 300)
  }

  return (
    <>
      <CSSTransition
        in={props.menuOpen}
        timeout={300}
        classNames="slideLeft"
        unmountOnExit
      >
        <FocusTrap>
          <nav id="mobNav" className="mobNav" ref={menuRef}>
            <div className="grid-y" style={{ minHeight: "100vh" }}>
              <MobileMenuHeader
                closeMobMenu={closeMobMenu}
                pageContext={props.pageContext}
              />

              <div className="cell auto">
                <ul className="mobNav__mainMenu" role="menu">
                  {mainMenu.map((item, index) => {
                    if (item.sub_nav && item.sub_nav.length > 0)
                      return (
                        <MobileMenuItem
                          key={item.id}
                          {...item}
                          index={index}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          pageContext={props.pageContext}
                        />
                      )
                    return (
                      <NavItem
                        key={item.id}
                        {...item}
                        cssClass="mobNav__menuItem"
                        pageContext={props.pageContext}
                      />
                    )
                  })}
                </ul>
                <div className="grid-container">
                  <div className="grid-x grid-margin-x">
                    <div className="cell">
                      <ul className="mobNav__footerMenu" role="menu">
                        {footerMenu.map((item: NavItemModel) => (
                          <NavItem
                            key={item.id}
                            {...item}
                            cssClass="mobNav__footerMenuItem"
                            pageContext={props.pageContext}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <MobileMenuFooter pageContext={props.pageContext}  />
            </div>
          </nav>
        </FocusTrap>
      </CSSTransition>
      <NavOverlay active={props.menuOpen} />
    </>
  )
}

MobileMenu.defaultProps = defaultProps

export default MobileMenu
