import React, { useState, useRef, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Scrollbars } from "react-custom-scrollbars-2"
import Modal from "react-modal"

import RichText from "../RichText"
import CloseIcon from "../../images/svg/icon_close.svg"

import useCurrentWidth from "../../hooks/useCurrentWidth"
import { useLocale } from "../../context/LocaleContext"
import { Config } from "../../models/config"
import { globalHistory } from '@reach/router'

interface Props {
  location: Location
  pageContext: any
}

const defaultProps = {}

Modal.setAppElement("#___gatsby")

export const WholiciousLivingModal: React.FC<Props> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      modal: allContentstackConfig(
        filter: { code_id: { eq: "popupWholiciousLiving" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  // state
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState<Config>()
  const [contentHeight, setContentHeight] = useState<number>()

  // refs
  const contentRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  // hooks
  const { locale, localeDir } = useLocale().localeData
  const pageWidth = useCurrentWidth()

  const updateHeights = useCallback(() => {
    if (contentRef.current) {
      setTimeout(() => {
        setContentHeight(contentRef.current?.getBoundingClientRect()?.height)
      }, 0)
    }
  }, [])

  useEffect(() => {
    updateHeights()
  }, [updateHeights, pageWidth])

  useEffect(() => {
    setModalData(
      query.modal.nodes.find((x: Config) => x.publish_details.locale === locale)
    )
  }, [query, locale])

  useEffect(() => {
    const now = new Date().getTime()
    const triggerDelay = modalData?.number || 30
    const triggerTime = now + 1000 * 60 * 60 * 24 * triggerDelay // 30 days
    const popupTrigger = JSON.parse(localStorage.getItem("wholicious") || "{}")

    const handleModal = (locale: string) => {
      // update popup trigger
      let newPopupTrigger = { ...popupTrigger }
      newPopupTrigger[locale] = triggerTime

      // clear the current timer
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }

      // save changes - with 10 second delay
      timerRef.current = setTimeout(() => {
        localStorage.setItem("wholicious", JSON.stringify(newPopupTrigger))
        setShowModal(true)
      }, 1000 * 10)
    }

    const checkModal = (pathname: string) => {
      if (
        locale &&
        modalData?.boolean &&
        props.location.pathname.includes(pathname) &&
        (!popupTrigger[locale] || popupTrigger[locale] < now)
      )
        handleModal(locale)
    }

    checkModal(`/${localeDir}/health-nutrition`)
  }, [props.location.pathname, locale, localeDir, modalData])

  // events
  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setShowModal(false)
    })
  }, [setShowModal])
  // is the modal disabled
  if (!modalData?.boolean) return null

  return (
    <Modal
      contentLabel="Wholicious Living"
      className="modal__sitePopup"
      isOpen={showModal && !!modalData}
      onAfterOpen={updateHeights}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
    >
      <div className="modal__content">
        {!!modalData?.image?.length && (
          <GatsbyImage
            image={modalData.image[0].gatsbyImageData}
            className="modal__background"
            alt={modalData.image[0].description || modalData.image[0].title}
            objectPosition="left"
          />
        )}
        <Scrollbars
          autoHide
          style={{
            height: `min(${contentHeight}px, 80vh)`,
          }}
        >
          <div className="modal__scroller" ref={contentRef}>
            <div className="modal__contentInner">
              <RichText
                content={modalData?.rich_text}
                pageContext={props.pageContext}
              />
              {modalData.text && (
                <iframe
                  src={modalData.text}
                  style={{
                    width: "100%",
                    height: 200,
                    border: "none",
                    padding: 5,
                    background: "#fff",
                    borderRadius: 4,
                  }}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
      <button className="modal__close" onClick={closeModal}>
        <CloseIcon width="20" height="20" />
        <span className="show-for-sr">close</span>
      </button>
    </Modal>
  )
}

WholiciousLivingModal.defaultProps = defaultProps

export default WholiciousLivingModal
