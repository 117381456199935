import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Icon_Facebook from "../../images/svg/icon_facebook.svg"
import Icon_Instagram from "../../images/svg/icon_instagram.svg"
import Icon_LinkedIn from "../../images/svg/icon_linkedIn.svg"
import Icon_YouTube from "../../images/svg/icon_youTube.svg"
import { useLocale } from "../../context/LocaleContext"
import { getLocaleConfig } from "../../utils/utils_locales"
import useDictionary from "../../hooks/useDictionary"

interface SocialLinksProps {
  pageContext: any
}

const defaultProps = {}

export const SocialLinks: React.FC<SocialLinksProps> = props => {
  const query = useStaticQuery(graphql`
    query {
      facebook: allContentstackConfig(filter: { code_id: { eq: "facebook" } }) {
        nodes {
          ...ConfigFields
        }
      }
      linkedIn: allContentstackConfig(filter: { code_id: { eq: "linkedIn" } }) {
        nodes {
          ...ConfigFields
        }
      }
      youTube: allContentstackConfig(filter: { code_id: { eq: "youTube" } }) {
        nodes {
          ...ConfigFields
        }
      }
      instagram: allContentstackConfig(
        filter: { code_id: { eq: "instagram" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  const { locale } = useLocale().localeData
  const facebook = getLocaleConfig(query.facebook, locale)?.text
  const linkedIn = getLocaleConfig(query.linkedIn, locale)?.text
  const youTube = getLocaleConfig(query.youTube, locale)?.text
  const instagram = getLocaleConfig(query.instagram, locale)?.text

  return (
    <div className="social__links">
      <span>{useDictionary("Follow Us", props.pageContext.locale)}</span>
      <SocialLink target={facebook}>
        <Icon_Facebook />
      </SocialLink>

      <SocialLink target={instagram}>
        <Icon_Instagram />
      </SocialLink>

      <SocialLink target={linkedIn}>
        <Icon_LinkedIn />
      </SocialLink>

      <SocialLink target={youTube}>
        <Icon_YouTube />
      </SocialLink>
    </div>
  )
}

SocialLinks.defaultProps = defaultProps

export default SocialLinks

interface SocialLinkProps {
  target?: string
  children?: React.ReactNode
}

const SocialLink: React.FC<SocialLinkProps> = props => {
  if (!props.target || !props.children) return null
  return (
    <a
      href={props.target}
      className="social__link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  )
}
