import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  jsonToHtml,
  IJsonToHtmlOptions,
} from "@contentstack/json-rte-serializer"

import { useLocale } from "../context/LocaleContext"
import { getPrettyURL, isEmptyRichText } from "../utils"
import RecipeSteps from "./RecipeSteps";


type listComponentType = "ol" | "ul"

const isListComponentType = (value: any): value is listComponentType => {
  return value === "ol" || value === "ul";
}


interface RichTextProps {
  content: any
  options?: string
  cssClass?: string
  figcaption?: boolean
  listType?: listComponentType
  pageContext: any
  stepsText?: string
}

const defaultProps = {}

interface SitePage {
  path: string
  pageContext: {
    uid: string
  }
}

export const RichText: React.FC<RichTextProps> = props => {
  // handle string content
  if (typeof props.content === "string") {
    return <div className={props.cssClass || ""}>{props.content}</div>
  }

  // validate props
  if (
      props.pageContext &&
      (!props.content ||
          isEmptyRichText(props.content) ||
          !props.pageContext.language)
  )
    return null

  // graphQL
  const query = useStaticQuery(graphql`
    query {
      sitePages: allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `)

  // match entry references to page URL
  const allPages = query.sitePages.nodes || []
  const walkChildren = (json: any, pages: SitePage[]) => {

    if (json?.children && json.children.length > 0) {
      for (let i = 0; i < json.children.length; i++) {
        if (
            json.children[i]?.type === "reference" &&
            json.children[i].attrs?.type === "entry"
        ) {
          const nodeAttrs = json.children[i].attrs
          const match = pages.find(
              x => x.pageContext.uid === nodeAttrs["entry-uid"]
          )
          if (match) {
            // override the entry URL and set current locale
            nodeAttrs.href = props.pageContext.language + match.path.slice(3)
          }
        }

        if (
            json.children[i]?.type === "a" &&
            (json.children[i].attrs?.url?.includes(
                    "https://www.sanitarium.com.au"
                ) ||
                json.children[i].attrs?.url?.includes(
                    "https://www.sanitarium.co.nz"
                ) ||
                json.children[i].attrs?.["redactor-attributes"]?.href?.includes(
                    "https://www.sanitarium.com.au"
                ) ||
                json.children[i].attrs?.["redactor-attributes"]?.href?.includes(
                    "https://www.sanitarium.co.nz"
                ))
        ) {
          if (json.children[i].attrs.url)
            json.children[i].attrs.url = json.children[i].attrs?.url
                ?.replace(
                    "https://www.sanitarium.com.au",
                    "/" + props.pageContext.language
                )
                ?.replace(
                    "https://www.sanitarium.co.nz",
                    "/" + props.pageContext.language
                )

          if (json.children[i].attrs.href)
            json.children[i].attrs["redactor-attributes"].href = json.children[
                i
                ].attrs?.["redactor-attributes"]?.href
                ?.replace(
                    "https://www.sanitarium.com.au",
                    "/" + props.pageContext.language
                )
                ?.replace(
                    "https://www.sanitarium.co.nz",
                    "/" + props.pageContext.language
                )

          if (json.children[i].attrs["redactor-attributes"])
            json.children[i].attrs["redactor-attributes"].target = "_self"
        }
        if (
            json.children[i]?.type === "a" &&
            !json.children[i].attrs?.url?.includes(
                "http" ||
                json.children[i].attrs?.["redactor-attributes"]?.href?.includes(
                    "http"
                )
            )
        ) {
          if (json.children[i].attrs.url)
            json.children[i].attrs.url =
                json.children[i].attrs?.url.toLowerCase()

          if (json.children[i].attrs.href)
            json.children[i].attrs["redactor-attributes"].href =
                json.children[i].attrs?.[
                    "redactor-attributes"
                    ]?.href.toLowerCase()
        }
        json.children[i] = walkChildren(json.children[i], pages)
      }
    }
    return json
  }
  const content = walkChildren(props.content, allPages)

  if (content) {

    if (props.figcaption)
      return (
          <figcaption
              dangerouslySetInnerHTML={{
                __html: jsonToHtml(content),
              }}
          />
      )

    if (isListComponentType(props.listType)) {
      return <RecipeSteps children={content.children} stepsText={props.stepsText} elementType={props.listType}/>
    }

    return (
        <div
            className={props.cssClass || ""}
            dangerouslySetInnerHTML={{
              __html: jsonToHtml(content, renderOptions),
            }}
        />
    )


  }

  return null
}

RichText.defaultProps = defaultProps

export default RichText

const renderOptions: IJsonToHtmlOptions = {
  customElementTypes: {
    // embed: (attrs, child, jsonBlock) => {
    //   console.log("embed - attrs: ", attrs)
    //   console.log("embed - child: ", child)
    //   console.log("embed - jsonBlock: ", jsonBlock)
    //   return `<p>EMBED</p>`
    // },
    reference: (attrs, child, jsonBlock) => {
      // fix unused props compilation error
      const attrsProps = attrs
      const childProps = child
      // console.log("reference - attrs: ", attrs)
      // console.log("reference - child: ", child)
      // console.log("reference - jsonBlock: ", jsonBlock)

      /****************************************************
       Links
       ****************************************************/
      if (jsonBlock.attrs["type"] === "entry") {
        let externalLink = /^https?:\/\//.test(jsonBlock.attrs.href)
        const link = externalLink
            ? jsonBlock.attrs.href
            : getPrettyURL(`/${jsonBlock.attrs.href}`)

        return `
          <a
            href="${link}"
            target="${jsonBlock.attrs.target}"
          >${child}</a>
        `
      }

      /****************************************************
       Images
       ****************************************************/
      if (jsonBlock.attrs["type"] === "asset") {
        const withHttps = (url: string) =>
            !/^https?:\/\//i.test(url) ? `https://${url}` : url

        const image = `
        <img
          src="${jsonBlock.attrs["asset-link"]}"
          alt="${jsonBlock.attrs["asset-alt"] || ""}"
          width="${jsonBlock.attrs.width || ""}"
        />`

        const link = jsonBlock.attrs.link
            ? `<a
                href="${withHttps(jsonBlock.attrs.link)}"
                target="${jsonBlock.attrs.target || "_self"}"
                style="display: inline-block"
              >`
            : ""

        // inline image
        if (jsonBlock.attrs["inline"]) {
          const position = jsonBlock.attrs.position || "none"
          return `
            <figure style="
              display: inline-block;
              float: ${position};
              clear: both;
              padding-bottom: 20px;
              padding-left: ${position === "right" ? "20px" : 0};
              padding-right: ${position === "left" ? "20px" : 0};
              max-width: ${
              jsonBlock.attrs.width
                  ? parseInt(jsonBlock.attrs.width) + 20 + "px"
                  : "none"
          }" data-imagesrc="${jsonBlock.attrs["asset-link"]}">
              ${link && `${link}`}
                ${image}
              ${link && `</a>`}
            </figure>
          `
        }

        // block image
        if (!jsonBlock.attrs["display"]) {
          const position = jsonBlock.attrs.position || "left"
          const caption = jsonBlock.attrs["asset-caption"]
              ? `<figcaption>${jsonBlock.attrs["asset-caption"]}</figcaption>`
              : ""
          return `
            <div class="embed" style="text-align: ${position}" data-imagesrc="${jsonBlock.attrs["asset-link"]}">
              <figure style="display: inline-block">
                ${link && `${link}`}
                  ${image}
                ${link && `</a>`}
                ${caption}
              </figure>
            </div>
          `
        }
      }
      return ``
    },
  },

  customTextWrapper: {},
}