import React from "react"

import NavItem from "../NavItem"
import GroupPreset from "../GroupPresets/GroupPreset"

import { NavGroupModel, NavItemModel } from "../../../models/nav"
import DesktopSubMenuCards from "./DesktopSubMenuCards"

interface Props {
  id: string
  nav?: NavGroupModel[]
  index: number
  offset: number
  buttonWidth?: number
  setActiveIndex: (x: number) => void
  pageContext: any
}

const defaultProps = {}

export const DesktopSubMenu: React.FC<Props> = props => {
  const handleMouseOver = () => props.setActiveIndex(props.index)
  const handleMouseOut = () => props.setActiveIndex(-1)

  return (
    <div
      id={props.id}
      className="subNav"
      style={{ marginLeft: props.offset }}
      aria-hidden={false}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className="subNav__highlight"
        style={{ width: props.buttonWidth || 0 }}
      />
      <div className="grid-x">
        {props.nav?.map((group, index) => (
          <div
            key={group.id}
            className={`cell ${index === 0 ? "shrink" : "auto"} ${
              group.preset === "Products" ? "" : "thin"
            }`}
          >
            {!!group.links?.length ? (
              <Links {...group} pageContext={props.pageContext} />
            ) : !!group.cards.length ? (
              <DesktopSubMenuCards
                cards_label={group.cards_label}
                cards={group.cards}
                pageContext={props.pageContext}
              />
            ) : group.preset ? (
              <GroupPreset
                type={group.preset}
                pageContext={props.pageContext}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

DesktopSubMenu.defaultProps = defaultProps

export default DesktopSubMenu

const Links: React.FC<{ links: NavItemModel[]; pageContext: any }> = props => {
  return (
    <ul className="subNav__menu" role="menu">
      {props.links.map(item => (
        <NavItem
          key={item.id}
          {...item}
          cssClass="subNav_menuItem"
          pageContext={props.pageContext}
        />
      ))}
    </ul>
  )
}
