/****************************************************

	Utilities - Constants

****************************************************/
import { NumberObj } from "../models/common"

/****************************************************
	Key Page URLs
****************************************************/
export const HEALTH_NUTRITION_PAGE = "/health-nutrition/"
export const SOCIAL_PURPOSE_PAGE = "/social-purpose/"
export const ABOUT_PAGE = "/about/"
export const RECIPES_PAGE = "/recipes/"
export const COLLECTIONS_PAGE = "/recipes/recipe-collections/"

/****************************************************
	Filter Select Styles
****************************************************/
export const filterSelectStyles = {
  // container: (styles: any) => ({
  //   ...styles,
  //   borderBottom: "2px solid #dfdee7",
  // }),
  // control: (styles: any) => ({
  //   ...styles,
  //   border: "none",
  //   boxShadow: "none",
  //   ":hover": { cursor: "pointer" },
  // }),
  // valueContainer: (styles: any) => ({ ...styles, paddingLeft: "0" }),
  // indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  // dropdownIndicator: (styles: any) => ({
  //   ...styles,
  //   color: "#262261",
  //   ":hover": { color: "#3ec1cf" },
  // }),
}

/****************************************************
	Forms
****************************************************/
export const TITLE_SELECT = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
  { value: "Mr & Mrs", label: "Mr & Mrs" },
  { value: "Master", label: "Master" },
]

/****************************************************
	Salesforce asset types
****************************************************/
export const SF_ASSET_LOOKUP: NumberObj = {
  ai: 16,
  psd: 17,
  pdd: 18,
  eps: 19,
  gif: 20,
  jpe: 21,
  jpeg: 22,
  jpg: 23,
  jp2: 24,
  jpx: 25,
  pict: 26,
  pct: 27,
  png: 28,
  tif: 29,
  tiff: 30,
  tga: 31,
  bmp: 32,
  wmf: 33,
  vsd: 34,
  pnm: 35,
  pgm: 36,
  pbm: 37,
  ppm: 38,
  svg: 39,
  "3fr": 40,
  ari: 41,
  arw: 42,
  bay: 43,
  cap: 44,
  crw: 45,
  cr2: 46,
  dcr: 47,
  dcs: 48,
  dng: 49,
  drf: 50,
  eip: 51,
  erf: 52,
  fff: 53,
  iiq: 54,
  k25: 55,
  kdc: 56,
  mef: 57,
  mos: 58,
  mrw: 59,
  nef: 60,
  nrw: 61,
  orf: 62,
  pef: 63,
  ptx: 64,
  pxn: 65,
  raf: 66,
  raw: 67,
  rw2: 68,
  rwl: 69,
  rwz: 70,
  srf: 71,
  sr2: 72,
  srw: 73,
  x3f: 74,
  "3gp": 75,
  "3gpp": 76,
  "3g2": 77,
  "3gp2": 78,
  asf: 79,
  avi: 80,
  m2ts: 81,
  mts: 82,
  dif: 83,
  dv: 84,
  mkv: 85,
  mpg: 86,
  f4v: 87,
  flv: 88,
  mjpg: 89,
  mjpeg: 90,
  mxf: 91,
  mpeg: 92,
  mp4: 93,
  m4v: 94,
  mp4v: 95,
  mov: 96,
  swf: 97,
  wmv: 98,
  rm: 99,
  ogv: 100,
  indd: 101,
  indt: 102,
  incx: 103,
  wwcx: 104,
  doc: 105,
  docx: 106,
  dot: 107,
  dotx: 108,
  mdb: 109,
  mpp: 110,
  ics: 111,
  xls: 112,
  xlsx: 113,
  xlk: 114,
  xlsm: 115,
  xlt: 116,
  xltm: 117,
  csv: 118,
  tsv: 119,
  tab: 120,
  pps: 121,
  ppsx: 122,
  ppt: 123,
  pptx: 124,
  pot: 125,
  thmx: 126,
  pdf: 127,
  ps: 128,
  qxd: 129,
  rtf: 130,
  sxc: 131,
  sxi: 132,
  sxw: 133,
  odt: 134,
  ods: 135,
  ots: 136,
  odp: 137,
  otp: 138,
  epub: 139,
  dvi: 140,
  key: 141,
  keynote: 142,
  pez: 143,
  aac: 144,
  m4a: 145,
  au: 146,
  aif: 147,
  aiff: 148,
  aifc: 149,
  mp3: 150,
  wav: 151,
  wma: 152,
  midi: 153,
  oga: 154,
  ogg: 155,
  ra: 156,
  vox: 157,
  voc: 158,
  "7z": 159,
  arj: 160,
  bz2: 161,
  cab: 162,
  gz: 163,
  gzip: 164,
  iso: 165,
  lha: 166,
  sit: 167,
  tgz: 168,
  jar: 169,
  rar: 170,
  tar: 171,
  zip: 172,
  gpg: 173,
  htm: 174,
  html: 175,
  xhtml: 176,
  xht: 177,
  css: 178,
  less: 179,
  sass: 180,
  js: 181,
  json: 182,
  atom: 183,
  rss: 184,
  xml: 185,
  xsl: 186,
  xslt: 187,
  md: 188,
  markdown: 189,
  as: 190,
  fla: 191,
  eml: 192,
  text: 193,
  txt: 194,
}
