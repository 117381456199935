import React, { useState, useRef, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Headroom from "react-headroom"

import LocaleLink from "../LocaleLink"
import BgImage from "../Image/BgImage"
import DesktopMenu from "../Nav/DesktopMenu/DesktopMenu"
import MobileMenu from "../Nav/MobileMenu/MobileMenu"
import Icon_Menu from "../../images/svg/icon_menu.svg"
import Logo_Sanitarium from "../../images/svg/sanitarium_logo.svg"
import Logo_Sanitarium_Au from "../../images/svg/Sanitarium_125-Years-AU_RGB-white-tagline.svg"
import Text_EatWell from "../../images/svg/text_eat_well_live_well.svg"

import { useLocale } from "../../context/LocaleContext"
import { getFirstArrayItem } from "../../utils"
import { getLocaleConfig } from "../../utils/utils_locales"
import useDictionary from "../../hooks/useDictionary"

interface HeaderProps {
  location: Location
  pageContext: any
}

const defaultProps = {}

export const Header: React.FC<HeaderProps> = props => {
  // query
  const query = useStaticQuery(graphql`
    query {
      headerBg: allContentstackConfig(filter: { code_id: { eq: "headerBg" } }) {
        nodes {
          ...ConfigFields
        }
      },
      headerSvgTxt: allContentstackConfig(filter: { code_id: { eq: "headerSvgTxt" } }) {
        nodes {
          ...ConfigFields
        }
      },
    }
  `)
  
  const locale = props.pageContext.locale
  const headerBg = getFirstArrayItem(
    getLocaleConfig(query.headerBg, locale)?.image
  )

  const bannerTxt = getFirstArrayItem(
    getLocaleConfig(query.headerSvgTxt, locale)?.image
  ) 
  || getFirstArrayItem(
    getLocaleConfig(query.headerSvgTxt)?.image
  )
  
  // state
  const [mobMenuOpen, setMobMenuOpen] = useState(false)

  // refs
  const logoRef = useRef<HTMLDivElement>(null)

  // effects
  useEffect(() => {
    logoRef?.current?.focus()
    logoRef?.current?.blur()
  }, [props.location?.pathname])

  return (
    <>
      <Headroom disableInlineStyles>
        <header ref={logoRef} tabIndex={-1}>
          <BgImage image={headerBg} cssClass="header__background">
            <div className="grid-container">
              <div className="grid-x grid-margin-x align-middle">
                <div
                  className="cell large-shrink"
                  style={{ position: "relative" }}
                >
                  <LocaleLink
                    to="/"
                    className="header__logo"
                    aria-label={useDictionary("Home", props.pageContext.locale)}
                    pageContext={props.pageContext}
                  >
                    <Logo_Sanitarium title={useDictionary("Sanitarium logo", props.pageContext.locale)} />
                  </LocaleLink>

                  <button
                    className="header__menuBtn hide-for-large"
                    onClick={() => setMobMenuOpen(true)}
                    aria-label={useDictionary("Open menu", props.pageContext.locale)}
                    aria-haspopup="true"
                    aria-controls="mobNav"
                  >
                    <Icon_Menu />
                  </button>
                </div>
                <div className="cell auto text-right show-for-large">
                  <LocaleLink
                    to="/"
                    className="header__text"
                    aria-label={useDictionary("Home", props.pageContext.locale)}
                    pageContext={props.pageContext}
                  >
                    <img width="339" height="43" src={bannerTxt.url} alt={useDictionary("Eat well. Live well.", props.pageContext.locale)} />
                  </LocaleLink>
                </div>
              </div>
            </div>
          </BgImage>
        </header>

        <DesktopMenu
          location={props.location}
          pageContext={props.pageContext}
        />
        <MobileMenu
          menuOpen={mobMenuOpen}
          setMobMenuOpen={setMobMenuOpen}
          location={props.location}
          pageContext={props.pageContext}
        />
      </Headroom>
    </>
  )
}

Header.defaultProps = defaultProps

export default Header
