import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import LocaleLink from "../../LocaleLink"

import { PageModel, ProductFlavourModel } from "../../../models/pages"
import { getPrettyURL, getPlainText} from "../../../utils"
import { getLocaleConfig } from "../../../utils/utils_locales"
import useDictionary from "../../../hooks/useDictionary"

interface GroupPresetProps {
  pageContext: any
}

const defaultProps = {}

export const ProductsGroup: React.FC<GroupPresetProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      items: allContentstackConfig(
        filter: { code_id: { eq: "menuProducts" } }
      ) {
        nodes {
          pages {
            ... on Contentstack_page {
              ...ContentPageFields
            }
          }
          publish_details {
            locale
          }
        }
      }

      feature: allContentstackConfig(
        filter: { code_id: { eq: "menuProductFeature" } }
      ) {
        nodes {
          pages {
            ... on Contentstack_product_flavour {
              ...ProductFlavourFields
            }
          }
          publish_details {
            locale
          }
        }
      }
    }
  `)

  const locale = props.pageContext.locale
  const items = getLocaleConfig(query.items, locale)?.pages || []
  const feature = (getLocaleConfig(query.feature, locale)?.pages ||
    []) as ProductFlavourModel[]

  // console.log("locale: ", locale)
  // console.log("items: ", items)
  // console.log("feature: ", feature)

  return (
    <>
      {items.length >= 1 && (
        <div className="subNav__products">
          <LocaleLink
            to="/products"
            className="subNav__productsLink"
            pageContext={props.pageContext}
          >
            {useDictionary("All Products", props.pageContext.locale)}
          </LocaleLink>
          <div style={{ columns: 2 }}>
            {items.map(product => (
              <ProductMiniCard
                key={product.id}
                {...product}
                pageContext={props.pageContext}
              />
            ))}
          </div>
        </div>
      )}
      {feature.length >= 1 && (
        <div className="subNav__products">
          <div className="subNav__heading">{useDictionary("Featured product", props.pageContext.locale)}</div>
          {feature.map(product => (
            <FeatureMiniCard
              key={product.id}
              {...product}
              pageContext={props.pageContext}
            />
          ))}
        </div>
      )}
    </>
  )
}

ProductsGroup.defaultProps = defaultProps

export default ProductsGroup

const ProductMiniCard: React.FC<PageModel> = props => {
  const listingImage = props.listing?.mini_img || props.hero?.mini_img
  const heading =
    getPlainText(props.nav_label) || getPlainText(props.hero?.heading) || ""

  return (
    <div className="miniProductCard">
      <div className="card__inner">
        <LocaleLink
          to={getPrettyURL(props)}
          className="card__link"
          pageContext={props.pageContext}
        >
          {listingImage && (
            <GatsbyImage
              image={listingImage?.gatsbyImageData}
              alt={heading}
              className="card__image"
              objectFit="contain"
              objectPosition="center"
              style={{ height: 70 }}
            />
          )}
          <div className="card__content">
            <div className="card__heading">{heading}</div>
          </div>
        </LocaleLink>
      </div>
    </div>
  )
}

const FeatureMiniCard: React.FC<ProductFlavourModel> = props => {
  const listingImage = props.listing?.mini_img || props.hero?.mini_img
  const heading =
    getPlainText(props.nav_label) || getPlainText(props.hero?.heading) || ""

  return (
    <div className="miniFeatureCard">
      <div className="card__inner">
        <LocaleLink
          to={getPrettyURL(props)}
          className="card__link"
          pageContext={props.pageContext}
        >
          {listingImage && (
            <GatsbyImage
              image={listingImage?.gatsbyImageData}
              alt={heading}
              className="card__image"
              objectFit="contain"
              objectPosition="center"
              style={{ height: 100 }}
            />
          )}
          <div className="card__content">
            <div className="card__heading">{heading}</div>
          </div>
        </LocaleLink>
      </div>
    </div>
  )
}
