/****************************************************

	Utilities - Images

****************************************************/
import { CmsImage } from "../models/common"
import { MediaModel } from "../models/components"

/****************************************************
	get alt text
****************************************************/
export const getAltText = (
  item?: MediaModel | CmsImage
): string | undefined => {
  if (!item) return undefined
  return item.hasOwnProperty("alt_text")
    ? getImageAltText(item as MediaModel)
    : getAssetAltText(item as CmsImage)
}
export const deBranchImage = (obj: any): any => {
  return JSON.parse(
    JSON.stringify(obj).replace(
      /\?branch=international\?/g,
      "?branch=international&"
    )
  )
}
const getImageAltText = (item?: MediaModel): string | undefined => {
  return item?.alt_text || getAssetAltText((item as MediaModel)?.image)
}

const getAssetAltText = (item?: CmsImage): string | undefined => {
  return item?.description || item?.title || undefined
}

export const getSKUImage = (imageUrl:string): string | undefined => {
  return "https://www.sanitarium.com/cdn-cgi/image/height=400,fit=contain,format=webp/" + imageUrl
}

/****************************************************
	meta tag image info
****************************************************/
export const buildShareImageMeta = (image: CmsImage | undefined) => {
  if (!image) return []

  return [
    {
      name: `twitter:image`,
      content: image.url,
    },
    {
      name: `image`,
      property: `og:image`,
      content: image.url,
    },
    {
      property: `og:image:type`,
      content: image.content_type,
    },
    {
      property: `og:image:width`,
      content: image.dimension?.width?.toString() || "",
    },
    {
      property: `og:image:height`,
      content: image.dimension?.height?.toString() || "",
    },
  ]
}
