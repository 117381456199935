import React from "react"

import MiniCard from "../../CardTypes/MiniCard"

import { getPlainText, getPrettyURL } from "../../../utils"
import { PageModels } from "../../../models/pages"
import useDictionary from "../../../hooks/useDictionary"

interface Props {
  cards_label?: string
  cards?: PageModels[]
  pageContext: any
}

const defaultProps = {}

export const DesktopSubMenuCards: React.FC<Props> = props => {
  if (!props.cards) return null

  return (
    <div className="subNav__articles">
      <div className="subNav__heading">
        {props.cards_label || useDictionary("Latest Articles", props.pageContext.locale)}
      </div>
      <div className="grid-x subNav__cards">
        {props.cards.map(item => {
          return (
            <div key={item.id} className="cell shrink">
              <MiniCard
                heading={getPlainText(item.hero.heading) || ""}
                link={getPrettyURL(item)}
                listingColor={item.listing?.listing_color}
                listingImage={item.listing?.listing_img || item.hero.mini_img}
                pageContext={props.pageContext}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

DesktopSubMenuCards.defaultProps = defaultProps

export default DesktopSubMenuCards
