exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-test-js": () => import("./../../../src/pages/form-test.js" /* webpackChunkName: "component---src-pages-form-test-js" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/AuthorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-campaign-page-tsx": () => import("./../../../src/templates/CampaignPage.tsx" /* webpackChunkName: "component---src-templates-campaign-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-product-flavour-page-tsx": () => import("./../../../src/templates/ProductFlavourPage.tsx" /* webpackChunkName: "component---src-templates-product-flavour-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-promotion-page-tsx": () => import("./../../../src/templates/PromotionPage.tsx" /* webpackChunkName: "component---src-templates-promotion-page-tsx" */),
  "component---src-templates-recipe-collection-page-tsx": () => import("./../../../src/templates/RecipeCollectionPage.tsx" /* webpackChunkName: "component---src-templates-recipe-collection-page-tsx" */),
  "component---src-templates-recipe-listing-page-tsx": () => import("./../../../src/templates/RecipeListingPage.tsx" /* webpackChunkName: "component---src-templates-recipe-listing-page-tsx" */),
  "component---src-templates-recipe-page-tsx": () => import("./../../../src/templates/RecipePage.tsx" /* webpackChunkName: "component---src-templates-recipe-page-tsx" */)
}

