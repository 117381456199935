export const DEFAULT_LANG = "au";


export const LOCALE_LOOKUPS = {
    au: { locale: "en-au" },
    nz: { locale: "en-nz" },
    fj: { locale: "en-fj" },
    pyf: { locale: "fr-pf" },
    hk: { locale: "zh-hk" },
    id: { locale: "id-id" },
    ko: { locale: "ko-kr" },
    ms: { locale: "en-my" },
    ncl: { locale: "fr-nc" },
    png: { locale: "en-pg" },
    ph: { locale: "en-ph" },
    sa: { locale: "ar-sa" },
    sg: { locale: "en-sg" },
    tw: { locale: "zh-tw" },
    th: { locale: "th-th" },
    vi: { locale: "vi-vn" },
}

export const getDefaultLocale = () => LOCALE_LOOKUPS[DEFAULT_LANG].locale;

export const LOCALE_DIRS = [
    "au",
    "nz",
    "fj",
    "pyf",
    "hk",
    "id",
    "ko",
    "ms",
    "ncl",
    "png",
    "ph",
    "sa",
    "sg",
    "tw",
    "th",
    "vi",
]