import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

interface NavOverlayProps {
  active: boolean
}

const defaultProps = {}

export const NavOverlay: React.FC<NavOverlayProps> = props => {
  // state

  return (
    <CSSTransition
      in={props.active}
      timeout={300}
      classNames="fade"
      unmountOnExit
    >
      <div className="navOverlay" />
    </CSSTransition>
  )
}

NavOverlay.defaultProps = defaultProps

export default NavOverlay
