/****************************************************

	Utilities - Links

****************************************************/
import { HierarchyModel } from "../models/common"
import { AnchorModel } from "../models/nav"
import { PageModels, ProductFlavourModel } from "../models/pages"
import { getHeroHeading, getPlainText } from "./utils_text"

/******************************************************
	get link (URL / Entry / Asset)
******************************************************/
export const getLink = (
  link: AnchorModel | undefined
): { url: string; isExternal: boolean } | undefined => {
  // asset
  if (link?.asset) return { url: link.asset.url, isExternal: true }

  // CMS entry
  if (link?.entry?.length) {
    return {
      url: getPrettyURL(link.entry[0]),
      isExternal: link.new_window || false,
    }
  }

  // URL
  if (link?.url && link.url.trim().length)
    return {
      url: link.url,
      isExternal: !/^\/(?!\/)/.test(link.url) || link.new_window || false,
    }

  return undefined
}

/******************************************************
	make the URL pretty
  - remove trailing slash
	- remove double slashes (but not protocol eg http://)
	- replace spaces with hyphens
	- remove double hyphens
	- make lowercase
******************************************************/
export const getPrettyURL = (url: string | PageModels | undefined): string => {
  if (!url) return ""

  if (typeof url === "string") {
    // break out any query strings parameters
    let urlParts = url.split("?")
    const baseURL = urlParts.shift()
    const prettyUrl = (baseURL || "")
      .replace(/\/$/, "")
      .replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
      .replace(/\ /g, "-")
      .replace(/\-+/g, "-")
      .toLowerCase()

    if (urlParts.length) return [prettyUrl, urlParts].join("?")
    return prettyUrl
  }

  return getPrettyURL(getPageSlugWithPrefix(url))
}

/******************************************************
	get the page URL structure
******************************************************/
export const buildBreadcrumbs = (page?: any): HierarchyModel[] => {
  if (page) {
    const pathParts = getUrlStructure(page)
    return pathParts.map((crumb, index) => {
      const previous = pathParts
        ?.slice(0, index + 1)
        ?.map(x => x.url)
        ?.join("/")
      return { ...crumb, url: getPrettyURL(previous) }
    })
  }
  return []
}

export const getUrlStructure = (pages?: PageModels[]): HierarchyModel[] => {
  if (!pages || pages.length === 0) return []
  else {
    return pages.map(page => {
      return {
        id: page.id,
        url: page.url,
        label: getNavLabel(page),
      }
    })
  }
}

const getNavLabel = (page?: PageModels) => {
  if (page) {
    return (
      getPlainText(page.nav_label) || getHeroHeading(page.hero?.heading) || ""
    )
  }
  return ""
}

/******************************************************
	get content type link prefix
******************************************************/
export const getPageSlugWithPrefix = (page: PageModels): string => {
  if (!page || !page.url) return "/"

  let url
  const urlStructure = getUrlStructure(page.url_structure)
    .map(x => x.url)
    .join("/")

  switch (page?.internal?.type) {
    case "Contentstack_article":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_page":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_author":
      url = `/authors/${page.url}`
      break
    case "Contentstack_campaign":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_promotion":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_product_flavour":
      let parents: PageModels[] = []
      const flavorPage = page as ProductFlavourModel
      let product = flavorPage.product ? flavorPage.product[0] : undefined
      if (product) parents = [...(product.url_structure || []), product]
      const buildUrl = getUrlStructure(parents)
        .map(x => x.url)
        .join("/")
      url = `${buildUrl}/${page.url}`
      break
    case "Contentstack_product":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_recipe_collection":
      url = `/recipes/vegetarian-recipe-collections/${page.url}`
      break
    case "Contentstack_recipe_listing":
      url = `${urlStructure}/${page.url}`
      break
    case "Contentstack_recipe":
      url = `/recipes/a-z/${page.url}`
      break
    default:
      url = `/${page.url}`
  }

  return url
}

/******************************************************
	get YouTube video ID
******************************************************/
export const getYtVideoId = (url?: string): string | undefined => {
  if (!url) return undefined
  else {
    const urlParams = new URLSearchParams(url.split("?").pop())
    const videoId = urlParams.get("v") || url.split("/").pop()

    return videoId
  }
}
