/****************************************************

	Utilities - Text

****************************************************/
import { jsonToHtml } from "@contentstack/json-rte-serializer"
import { parse } from "node-html-parser"

/******************************************************
	Format form date for submit
******************************************************/
export const formatFormDate = (dateString: string) => {
  // changed to text now
  
  const date = new Date(Date.parse(dateString)); 
  return `${date.getDate().toString()
    .padStart(2, '0')}/${(date.getMonth() + 1).toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
  
  
  //return dateString;
  // if (typeof dateString !== "string") return undefined
  // const dateParts = dateString.split("/")
  // return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]
}

/******************************************************
	Hero heading from RichText
******************************************************/
export const getHeroHeading = (data: any) => {
  if (!data) return undefined
  if (typeof data === "string") return data

  return getPlainText(data)
}

/******************************************************
	Is Rich Text field actually empty
******************************************************/
export const isEmptyRichText = (content: any | undefined): boolean => {
  return !!!getPlainText(content)?.length
}

/******************************************************
	Get the plain text from a rich text field
******************************************************/
export const getPlainText = (content: any | undefined): string | undefined => {
  if (typeof content === "string") return content
  if (content) {
    // create markers for lists
    const html = jsonToHtml(content)
      .replace(/<li>/g, "<li>• ")
      .replace(/<\/li>/g, " </li>")

    // create dom and get innerText
    const dom = parse(`<div id="htmlWrap">${html}</div>`)
    const plaintext = dom?.querySelector("#htmlWrap")?.innerText

    return plaintext?.replace(/\n/g, " ")?.replace(/ +/g, " ")?.trim() || ""
  }

  return undefined
}

/******************************************************
	Turn a rich text list into and array of strings
******************************************************/
export const getPlainTextList = (
  content: any | undefined
): string[] | undefined => {
  if (content) {
    // create split points
    const html = jsonToHtml(content).replace(/<\/li>/g, "|--|</li>")

    // create dom and get innerText
    const dom = parse(`<div id="htmlWrap">${html}</div>`)
    const plaintext = dom?.querySelector("#htmlWrap")?.innerText || ""

    // pull out list content
    const list = plaintext
      .replace(/((<ul>|<ol>)|<li>(.*)<\/li>|(<\/ul>|<\/ol>))/g, "$3")
      .split("|--|")
      .filter(Boolean)
      .map((part: string) => part.trim())

    return list
  }

  return undefined
}

/******************************************************
	remove empty paragraph tags for API
******************************************************/
export const cleanHtml = (html: string): string => {
  return html.replace(/<p><\/p>/g, "")
}
