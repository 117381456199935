import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import LocaleLink from "../LocaleLink"

import { CmsImage } from "../../models/common"
import { getPlainText } from "../../utils"

interface Props {
  heading: string
  listingImage?: CmsImage
  listingColor?: string
  link: string
  pageContext: any
}

const defaultProps = {}

export const MiniCard: React.FC<Props> = props => {
  if (!props.heading || !props.link) return null

  if (props.listingImage) return <ImageCard {...props} />
  return <ColorCard {...props} />
}

MiniCard.defaultProps = defaultProps

export default MiniCard

const ImageCard: React.FC<Props> = props => {
  const listingImage = props.listingImage

  return (
    <div className="miniCard imageCard">
      <div className="grid-y card__inner">
        <LocaleLink
          to={props.link}
          className="cell auto card__link"
          pageContext={props.pageContext}
        >
          {listingImage && (
            <GatsbyImage
              image={listingImage?.gatsbyImageData}
              alt={getPlainText(props.heading) || ""}
              className="card__image"
              objectFit="cover"
              objectPosition="center"
              style={{ height: 75 }}
            />
          )}
          <div className="card__heading">{props.heading}</div>
        </LocaleLink>
      </div>
    </div>
  )
}

const ColorCard: React.FC<Props> = props => {
  return (
    <div className={`miniCard colorCard ${props.listingColor || "color--1"}`}>
      <div className="grid-y card__inner">
        <LocaleLink
          to={props.link}
          className="cell auto card__link"
          pageContext={props.pageContext}
        >
          <div className="card__content">
            <div className="card__heading">{props.heading}</div>
          </div>
        </LocaleLink>
      </div>
    </div>
  )
}
