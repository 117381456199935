/****************************************************

	Hook - Get dictionary translation

****************************************************/
import { useStaticQuery, graphql } from "gatsby"
import { getLocaleConfig } from "../utils/utils_locales"

export const useDictionary = (term: string, locale: string = "en-au") => {
  const query = useStaticQuery(graphql`
    query {
      dictionary: allContentstackConfig(
        filter: { code_id: { eq: "dictionary" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)
  const dictionary = getLocaleConfig(query.dictionary, locale)
  if (dictionary?.dictionary?.value) {
    const word = dictionary?.dictionary?.value?.find(
      (x: { key: string }) => x.key === term
    )?.value
    if (word) return word
  }
  // nothing found - return original term
  // console.log(term)
  return term
}

export default useDictionary
