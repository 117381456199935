import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { Script  } from "gatsby";

import Transition from "../components/Layout/Transition"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import RecipeOfTheWeekModal from "../components/Modals/RecipeOfTheWeekModal"
import WholiciousLivingModal from "../components/Modals/WholiciousLivingModal"

import { useLocale } from "../context/LocaleContext"
import { getLangLocale } from "../utils/utils_locales"

const hotJarScript = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3850409,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

interface Props {
  children: React.ReactNode
  location: Location
  pageContext: any
}

const defaultProps = {
  pageContext: {},
}

const Layout = ({ children, location, pageContext }: Props) => {
  const { setLocaleData } = useLocale()

  useEffect(() => {
    if (pageContext.locale) {
      setLocaleData({
        locale: pageContext.locale,
        localeDir: pageContext.language,
      })
    }
  }, [pageContext.language, pageContext.locale])

  if (!pageContext.language) {
    const langPart = location.pathname.split("/").filter(Boolean)[0];
    const { locale, lang } = getLangLocale(langPart);

    pageContext = {
      locale: locale,
      localeDir: lang,
      language: lang,
    }
  }
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,400italic,300,300italic,500,500italic,700,700italic"
          rel="stylesheet"
        />        
        <script type="text/javascript"> 
          {hotJarScript}
        </script>
      </Helmet>

      <Header location={location} pageContext={pageContext} />

      <main>
        <Transition location={location}>{children}</Transition>
      </main>

      <Footer location={location} pageContext={pageContext} />

      <RecipeOfTheWeekModal location={location} pageContext={pageContext} />
      <WholiciousLivingModal location={location} pageContext={pageContext} />

      <Script id="link-tuner-script" strategy="post-hydrate">
        {`

          function getTheAnchorElement(element) {
            if(element.tagName.toLowerCase() === "body") {
              return null;
            }

            if(element.tagName.toLowerCase() === "a") {
              return element;
            }

            return getTheAnchorElement(element.parentElement);
          }

          const body = document.querySelector("body");     

          body.addEventListener("click", function(event) {
            const anchor = getTheAnchorElement(event.target);

            if(!anchor) {
              return;
            }

            const href = anchor.getAttribute("href");
            if(href.startsWith("/")) {
              return;
            }
            
            const origin = window.location.origin;
            if(href.startsWith(origin)) {
              return;
            }

            const target = "target";
            const _blank = "_blank";

            if(anchor.getAttribute(target) !== _blank) {
               anchor.setAttribute(target, _blank);
            }            
          }, true);        

          `}
      </Script>
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
