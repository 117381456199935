import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"

import useOnClickOutside from "../../hooks/useOnClickOutside"

import AustraliaFlag from "../../images/svg/flag_au.svg"
import NewZealandFlag from "../../images/svg/flag_nz.svg"
import ThailandFlag from "../../images/svg/flag_th.svg"
import MalaysiaFlag from "../../images/svg/flag_my.svg"
import KoreaFlag from "../../images/svg/flag_kr.svg"
import PhilippinesFlag from "../../images/svg/flag_ph.svg"
import SingaporeFlag from "../../images/svg/flag_sg.svg"
import IndonesiaFlag from "../../images/svg/flag_id.svg"
import TaiwanFlag from "../../images/svg/flag_tw.svg"
import VietnamFlag from "../../images/svg/flag_vn.svg"
import PapuaNewGuineaFlag from "../../images/svg/flag_pg.svg"
import ChinaFlag from "../../images/svg/flag_cn.svg"
import FijiFlag from "../../images/svg/flag_fj.svg"
import TahitiFlag from "../../images/svg/flag_pf.svg"
import HongKongFlag from "../../images/svg/flag_hk.svg"
import NewCaledoniaFlag from "../../images/svg/flag_nc.svg"

interface Props {
  language: string
  location: Location
  pageContext: any
}

const defaultProps = {}

export const LanguageSelector: React.FC<Props> = props => {
  const [country, setCountry] = useState<string>()
  const [path, setPath] = useState<string>()
  const [open, setOpen] = useState(false)

  const optionsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.language) setCountry(props.language)
  }, [props.language])

  useEffect(() => {
    if (props.location.pathname) {
      setOpen(false)
      setPath(
        props.location.pathname.split("/").filter(Boolean).slice(1).join("/")
      )
    }
  }, [props.location.pathname])

  useOnClickOutside(optionsRef, () => setOpen(false))

  return (
    <div className={`langSelector ${open ? "open" : ""}`}>
      {!!country && (
        <button
          className="langSelector__selected"
          onClick={() => setOpen(!open)}
        >
          <span className="langSelector__flag">
            <Flag country={country} />
          </span>
          <span style={{ verticalAlign: "middle" }}>
            {languages[country].label}
          </span>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="langSelector__arrow"
          >
            <path
              d="M8.07602 8.29492L12.666 12.8749L17.256 8.29492L18.666 9.70492L12.666 15.7049L6.66602 9.70492L8.07602 8.29492Z"
              fill="#003DA6"
            />
          </svg>
        </button>
      )}

      <CSSTransition
        in={open}
        timeout={300}
        classNames="fadefast"
        unmountOnExit
      >
        <div className="langSelector__options" ref={optionsRef}>
          {Object.keys(languages).map(item => {
            const langLink =
              props.pageContext &&
              props.pageContext.langs &&
              props.pageContext.langs.find((x: any) => x.locale === item)

            return (
              <Link
                key={item}
                to={
                  langLink
                    ? langLink.url.startsWith("/")
                      ? langLink.url
                      : `/${langLink.url}`
                    : `/${item}`
                }
                className={`langSelector__option ${langLink ? "active" : ""}`}
                onClick={() => setCountry(item)}
              >
                <Flag country={item} />
                {languages[item].label}
              </Link>
            )
          })}
        </div>
      </CSSTransition>
    </div>
  )
}

LanguageSelector.defaultProps = defaultProps

export default LanguageSelector

const Flag: React.FC<{ country: string }> = props => {
  return (
    <>
      {props.country === "au" && <AustraliaFlag />}
      {props.country === "nz" && <NewZealandFlag />}
      {props.country === "th" && <ThailandFlag />}
      {props.country === "ms" && <MalaysiaFlag />}
      {props.country === "ko" && <KoreaFlag />}
      {props.country === "ph" && <PhilippinesFlag />}
      {props.country === "sg" && <SingaporeFlag />}
      {props.country === "id" && <IndonesiaFlag />}
      {props.country === "tw" && <TaiwanFlag />}
      {props.country === "vi" && <VietnamFlag />}
      {props.country === "png" && <PapuaNewGuineaFlag />}
      {/* {props.country === "cn" && <ChinaFlag />} */}
      {props.country === "fj" && <FijiFlag />}
      {props.country === "pyf" && <TahitiFlag />}
      {props.country === "hk" && <HongKongFlag />}
      {props.country === "ncl" && <NewCaledoniaFlag />}
    </>
  )
}

interface LangModel {
  label: string
  flag: SVGElement
}

interface LangObj {
  [index: string]: LangModel
}

const languages: LangObj = {
  au: { label: "Australia", flag: AustraliaFlag },
  nz: { label: "New Zealand", flag: NewZealandFlag },
  th: { label: "ไทย", flag: ThailandFlag },
  ms: { label: "Malaysia", flag: MalaysiaFlag },
  ko: { label: "한국어", flag: KoreaFlag },
  ph: { label: "Philippines", flag: PhilippinesFlag },
  sg: { label: "Singapore", flag: SingaporeFlag },
  id: { label: "Indonesia", flag: IndonesiaFlag },
  tw: { label: "Taiwan", flag: TaiwanFlag },
  vi: { label: "Tiếng Việt", flag: VietnamFlag },
  png: { label: "PNG", flag: PapuaNewGuineaFlag },
  // cn: { label: "中国", flag: ChinaFlag },
  fj: { label: "Fiji", flag: FijiFlag },
  pyf: { label: "Tahiti", flag: TahitiFlag },
  hk: { label: "香港", flag: HongKongFlag },
  ncl: { label: "Nouvelle Calédonie", flag: NewCaledoniaFlag },
}
