import React from "react"

import LatestNutrition from "./LatestNutrition"
import LatestSocial from "./LatestSocial"
import LatestAbout from "./LatestAbout"
import ProductsGroup from "./ProductsGroup"
import RecipesGroup from "./RecipesGroup"

interface GroupPresetProps {
  type: string
  pageContext: any
}

const defaultProps = {}

export const GroupPreset: React.FC<GroupPresetProps> = props => {
  if (props.type === "Products")
    return <ProductsGroup pageContext={props.pageContext} />
  if (props.type === "Recipes")
    return <RecipesGroup pageContext={props.pageContext} />
  if (props.type === "Latest Health & Nutrition")
    return <LatestNutrition pageContext={props.pageContext} />
  if (props.type === "Latest Social Purpose")
    return <LatestSocial pageContext={props.pageContext} />
  if (props.type === "Latest About")
    return <LatestAbout pageContext={props.pageContext} />
  return null
}

GroupPreset.defaultProps = defaultProps

export default GroupPreset
