import React from "react"

type ListItem = {
    text: string;
    index: number;
    stepsText?: string;
}

const ListItem: React.FC<ListItem> = ({text, index, stepsText = "Step"}) => {
    const incrementedStep = index + 1;
    return <li>
        <p style={{color: "#ef2c32", display: "block", fontWeight: 500, fontSize: "inherit"}}>{`${stepsText} ${incrementedStep}`}</p>
            {text}
    </li>
}

type ChildNode = {
    children: {
        children: {
            text: string;
        }[];
        text: string;
    }[];
    type: string;
}

interface MethodProps {
    children: {
        children: ChildNode[];
        type: string
    }[];
    stepsText?: string;
    elementType: string;
}

const RecipeSteps: React.FC<MethodProps> = ({children, stepsText, elementType}) => {
    const olChild = children.find(child => child.type === elementType)
    const liItems = olChild?.children?.map(childNode => {
                if (childNode.type === "li") return childNode.children[0]?.text ?? childNode.children[0]?.children[0]?.text;
            }).filter((liItem): liItem is string => !!liItem);

    return <div className={"content"}>
    <ol>
        {
            liItems?.map((liItemText, index) => {
                return <ListItem text={liItemText} index={index} key={index} stepsText={stepsText}/>
            })}
    </ol>
    </div>

}

export default RecipeSteps;