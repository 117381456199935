import React from "react"
import cx from "classnames"

import LocaleLink from "../../LocaleLink"

import { getLink } from "../../../utils"
import { NavItemModel } from "../../../models/nav"
import { LinkData } from "../../../models/common"

interface DesktopMenuItemProps extends NavItemModel {
  index: number
  activeIndex: number
  ref: React.RefObject<HTMLLIElement>
  setActiveIndex: (x: number) => void
  pageContext: any
}

export const DesktopMenuItemRenderFn: React.ForwardRefRenderFunction<
  HTMLLIElement,
  DesktopMenuItemProps
> = (props, ref) => {
  if (
    !props.main_link ||
    !props.label ||
    !props.sub_nav ||
    props.sub_nav.length === 0
  )
    return null

  // get the main link
  const linkData: LinkData | undefined = getLink(props.main_link[0])
  if (!linkData) return null

  // events
  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      props.setActiveIndex(props.activeIndex === -1 ? props.index : -1)
    }
    if (event.key === "Escape") {
      props.setActiveIndex(-1)
    }
    if (event.key === "ArrowDown") {
    }
  }

  const handleMouseOver = () => props.setActiveIndex(props.index)

  const handleFocus = () => {
    if (props.activeIndex !== props.index) props.setActiveIndex(-1)
  }

  return (
    <li className="nav_menuItem" role="presentation" ref={ref}>
      <LocaleLink
        to={linkData.url}
        className={`navLink ${
          props.activeIndex === props.index ? "active" : ""
        }`}
        target={linkData.isExternal ? "_blank" : undefined}
        role="menuitem"
        onKeyDown={handleKeyDown}
        onMouseOver={handleMouseOver}
        onFocus={handleFocus}
        aria-controls={props.id}
        aria-haspopup="true"
        aria-expanded={props.activeIndex === props.index}
        pageContext={props.pageContext}
      >
        {props.label}
      </LocaleLink>
    </li>
  )
}

const DesktopMenuItem = React.forwardRef(DesktopMenuItemRenderFn)

export default DesktopMenuItem
