import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import MiniCard from "../../CardTypes/MiniCard"

import { useLocale } from "../../../context/LocaleContext"
import { getPlainText, getPrettyURL } from "../../../utils"
import { ArticleModel } from "../../../models/pages"
import useDictionary from "../../../hooks/useDictionary"

interface GroupPresetProps {
  pageContext: any
}

const defaultProps = {}

export const LatestSocial: React.FC<GroupPresetProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      items: allContentstackArticle(
        filter: {
          article_type: { eq: "Social Purpose" }
          title: { ne: "do not delete" }
        }
        sort: { fields: publish_date, order: DESC }
        limit: 20
      ) {
        nodes {
          ...ArticleFields
        }
      }
    }
  `)

  const locale = props.pageContext.locale
  const listing: ArticleModel[] = query.items.nodes
    .filter((x: ArticleModel) => x.publish_details.locale === locale)
    .slice(0, 6)

  if (listing.length === 0) return null

  return (
    <div className="subNav__articles">
      <div className="subNav__heading">{useDictionary("Latest Articles", props.pageContext.locale)}</div>
      <div className="grid-x subNav__cards">
        {listing.map(article => {
          return (
            <div key={article.id} className="cell shrink">
              <MiniCard
                heading={getPlainText(article.hero.heading) || ""}
                link={getPrettyURL(article)}
                listingColor={article.listing?.listing_color}
                listingImage={
                  article.listing?.listing_img || article.hero.mini_img
                }
                pageContext={props.pageContext}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

LatestSocial.defaultProps = defaultProps

export default LatestSocial
