import React from "react"

import SocialLinks from "../../Social/SocialLinks"

interface MobileMenuFooterProps {
  pageContext: any
}

const defaultProps = {}

export const MobileMenuFooter: React.FC<MobileMenuFooterProps> = props => {
  return (
    <div className="cell shrink mobNav__footer">
      <div className="grid-container">
        <SocialLinks pageContext={props.pageContext} />
      </div>
    </div>
  )
}

MobileMenuFooter.defaultProps = defaultProps

export default MobileMenuFooter
