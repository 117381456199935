import React, { useState, useMemo, useContext, createContext } from "react"

type LocaleModel = {
  locale?: string
  localeDir?: string
}

type ContextModel = {
  localeData: LocaleModel
  setLocaleData: (data: LocaleModel) => void
}

const defaultLocaleContext: LocaleModel = {
  locale: "",
  localeDir: "",
}

const locale_Init = {
  localeData: defaultLocaleContext,
  setLocaleData: (data: LocaleModel) => console.log(data),
}

const LocaleContext = createContext(locale_Init)
export const useLocale = () => useContext(LocaleContext)

export const LocaleContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [localeData, setLocaleData] = useState(locale_Init.localeData)
  const Locale_ContextValue = useMemo(
    () => ({ localeData, setLocaleData }),
    [localeData]
  )

  return (
    <LocaleContext.Provider value={Locale_ContextValue}>
      {children}
    </LocaleContext.Provider>
  )
}
