import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LocaleLink from "../LocaleLink"
import NavItem from "../Nav/NavItem"
import SocialLinks from "../Social/SocialLinks"
import Logo_Sanitarium from "../../images/svg/sanitarium_logo.svg"
import Logo_Sanitarium_Au from "../../images/svg/Sanitarium_125-Years-AU_RGB.svg"

import { NavItemModel } from "../../models/nav"
import { getLocaleConfig } from "../../utils/utils_locales"
import LanguageSelector from "./LanguageSelector"
import useDictionary from "../../hooks/useDictionary"
import { showLanguageSelector } from "../../utils"

interface Props {
  location: Location
  pageContext: any
}

const defaultProps = {}

export const Footer: React.FC<Props> = props => {
  const query = useStaticQuery(graphql`
    query {
      menu: allContentstackConfig(filter: { code_id: { eq: "footerMenu" } }) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  const footerMenu = (
    getLocaleConfig(query.menu, props.pageContext.locale)?.navigation || []
  ).filter(
    x => x.internal?.type === "Contentstack_navigation_item"
  ) as NavItemModel[]
//console.log("footerMenu: ", footerMenu)
  return (
    <footer>
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell large-6 footer__social">
            <SocialLinks pageContext={props.pageContext} />
          </div>
          <div className="cell footer__links">
            <ul className="footer__menu" role="menu">
              {footerMenu.map(item => (
                <NavItem
                  key={item.id}
                  {...item}
                  cssClass="footer_menuItem"
                  pageContext={props.pageContext}
                />
              ))}
              <li className="footer__copyright">
                &copy; {new Date().getFullYear()} {useDictionary("Sanitarium", props.pageContext.locale)}
              </li>
            </ul>
          </div>
          <div className="cell large-auto footer__language">
              <LanguageSelector
                location={props.location}
                language={props.pageContext?.language || "au"}
                pageContext={props.pageContext}
              />
          </div>
          <div className="cell large-shrink footer__logo">
            {props.pageContext.locale === "en-au" && (
              <LocaleLink
                to="/about/media-room/125-years-of-serving-goodness"
                className="footer__logoLinkAu"
                aria-label={useDictionary("Home", props.pageContext.locale)}
                pageContext={props.pageContext}
              >
                <Logo_Sanitarium_Au title={useDictionary("Sanitarium logo", props.pageContext.locale)} />
              </LocaleLink>
            )}
            {props.pageContext.locale != "en-au" && (
              <LocaleLink
                to="/"
                className="footer__logoLink"
                aria-label={useDictionary("Home", props.pageContext.locale)}
                pageContext={props.pageContext}
              >
                <Logo_Sanitarium title={useDictionary("Sanitarium logo", props.pageContext.locale)} />
              </LocaleLink>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = defaultProps

export default Footer
